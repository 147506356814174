.mobile .portfolio-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  .triple-card {
    width: 100% !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
    margin-left: 0px !important;
  }
}

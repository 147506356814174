@import "components/headerfooter/headerfooter.scss";
@import "components/cards/cards.scss";
@import "components/portfolio/portfolio.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a[role=button] {
  min-width: 200px;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

@keyframes fadeInUp {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1.5s fadeInUp;
}
footer {
  background-color: rgb(28, 26, 27);
  color: white;
  .card {
    background-color: rgb(28, 26, 27);
    color: white;
    border: 0px;
  }
  h5 {
    margin-bottom: 1rem;
  }
  h6 {
    margin-bottom: 1rem;
  }
}

.mobile footer .card {
  width: 100% !important;
}
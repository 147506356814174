.desktop {
  .floating-card {
    position: relative;
    top: -3rem;
    z-index: 2;
  }
}

.mobile {
  .floating-card {
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
  .w-50 {
    width: 100% !important;
  }
  .w-30 {
    width: 100% !important;
  }
  .triple-card {
    width: 100% !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}
.object-fit-cover {
  object-fit: cover;
}
.mobile .reviews .triple-card {
  margin-top: 2em !important;
}
.reviews .profile {
  display: block;
  height: 4.95em;
  width: 4.95em;
  margin-left: 0;
  border-radius: 50%;
  margin-bottom: 0.8em;
  position: absolute;
  left: 1.5em;
  top: -2.55em;
}

.w-30 {
  width: 30%;
}

@media only screen and (max-width: 700) {
  .w-100 {
    width: 30%;
  }
}

.text-prewrap {
  white-space: pre-wrap;
}

.triple-card {
  width: 30%;
}
.middle-card {
  margin-left: auto;
  margin-right: auto;
}
